import { createRouter, createWebHistory } from "vue-router";

const routerHistory = createWebHistory();
// 公共路由
const routes = [
   {
      path: "/",
      name: "Login",
      component: () => import("../components/Login.vue")
   },
   {
      path: "/qradmin",
      name: "QrAdmin",
      component: () => import("../components/QrAdmin.vue")
   }
];

const router = createRouter({
   history: routerHistory,
   routes
});

export default router;

