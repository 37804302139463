<template>

   <div
      id="app"
      class="app-main-content"
   >
      <router-view
         ref="scale-test"
         class="scale-test"
      />
   </div>
</template>

<script>

export default {
   name: 'App',
}
</script>

<style>
#app {
   font-family: Avenir, Helvetica, Arial, sans-serif;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
   text-align: center;
   color: #2c3e50;
   width: 100%;
   height: 100%;
   min-height: 100%;
}
</style>
